import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import classnames from 'classnames';
import { useNavigate } from 'react-router-dom';

import { ArrowBackSVGpath } from '@skatteetaten/ds-icons';
import { InlineButton } from '@skatteetaten/ds-buttons';
import style from './StickyHeader.module.scss';
import { Soek } from './soek/Soek';
import { TemaIkon } from './tema/TemaIkon';
import { useMediaQuery } from '../../hooks/mediaQueryHook';
import { useDisableScroll } from '../../hooks/useDisableScroll';
import { useScrolledPast } from '../../hooks/useScrolledPast';
import { getSynligSeksjon } from '../../reducers/navigasjon';
import { useAnalytics } from '../../analytics/analytics.hooks';
import { useKildeSkattPaaLoenn } from './kildeskattePaaLoenn/useKildeskattPaaLoenn';

const stickyHeaderId = 'stickyHeader';

export const circaHeaderHoeyde = 130;

const HeaderWrapper = ({ children }) => {
  const synligSeksjon = useSelector(getSynligSeksjon);
  const ref = useRef();
  const [contentHeight, setContentHeight] = useState(0);
  const isScrolledPast = useScrolledPast(ref);

  useEffect(() => {
    setContentHeight(ref.current?.offsetHeight);
  }, [ref, synligSeksjon]); // tema er med for å rekalkulere høyde når det tegnes inn/ut

  return (
    <div style={{ height: contentHeight }}>
      <div
        id={stickyHeaderId}
        className={isScrolledPast ? style.sticky : style.header}
        ref={ref}
      >
        {children}
      </div>
    </div>
  );
};

export const StickyHeader = () => {
  const intl = useIntl();

  const synligSeksjon = useSelector(getSynligSeksjon);

  const { mobile, desktop } = useMediaQuery();
  const [visSoekInput, settVisSoekInput] = useState(desktop);
  useEffect(() => settVisSoekInput(desktop), [desktop]);
  const visKunSoek = mobile && visSoekInput;
  useDisableScroll(visKunSoek);

  const { beregnKplModus } = useKildeSkattPaaLoenn();

  if (beregnKplModus) {
    return <TilbakeTilSkattepliktSideLink />;
  }

  if (visKunSoek) {
    return (
      <HeaderWrapper>
        <TilbakeTilSkattepliktSideLink />
        <Soek
          visInput={visSoekInput}
          settVisInput={settVisSoekInput}
          mediaDesktop={desktop}
        />
      </HeaderWrapper>
    );
  }

  return (
    <HeaderWrapper>
      <div className={style.headerRow}>
        <div className={style.headerRowInnhold}>
          <TilbakeTilSkattepliktSideLink />
          <div className={style.soekContainer}>
            <Soek
              visInput={visSoekInput}
              settVisInput={settVisSoekInput}
              mediaDesktop={desktop}
            />
          </div>
        </div>
      </div>
      <div
        className={classnames({
          [style.headerRow]: true,
          [style.temaRow]: true,
          [style.temaRowOpen]: synligSeksjon,
        })}
        aria-hidden={!synligSeksjon}
      >
        {synligSeksjon ? (
          <div className={style.temaRowInnhold}>
            <TemaIkon id={synligSeksjon} className={style.temaIkon} />
            {intl.formatMessage({ id: synligSeksjon })}
          </div>
        ) : null}
      </div>
    </HeaderWrapper>
  );
};

export const TilbakeTilSkattepliktSideLink = () => {
  const intl = useIntl();
  const navigateTo = useNavigate();
  const analytics = useAnalytics();

  return (
    <InlineButton
      onClick={() => {
        navigateTo('/skattekalkulator/skatteplikt');
        analytics.knappKlikk('Naviger til forrige side', 'Skattemeldingssiden');
      }}
      svgPath={ArrowBackSVGpath}
    >
      {intl.formatMessage({
        id: 'ledetekst.navigering.tilbakeTilTilpasningsside',
      })}
    </InlineButton>
  );
};
