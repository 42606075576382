import { visningslogikk } from '@skatteetaten/visningsdefinisjoner-og-tekster';
import { Radiogruppe } from './knapper/Radiogruppe';
import Avkrysningsboks from './knapper/Avkrysningsboks';
import { Dropdown } from './Dropdown';

const { felt: feltUtils } = visningslogikk;

export const Opsjon = ({
  disabled,
  felt,
  verdi,
  onChange,
  onFocus,
  feil,
  valider,
  obligatorisk,
  valideringsstate,
  feltReferanse,
}) => {
  const valgliste = felt.opsjon.verdier;

  if (feltUtils.feltErAvkrysningsboks(felt)) {
    return (
      <Avkrysningsboks
        felt={felt}
        verdi={verdi}
        disabled={disabled}
        onChange={onChange}
        onFocus={onFocus}
        feil={feil}
        valideringsstate={valideringsstate}
      />
    );
  }

  if (feltUtils.feltErDropdown(felt)) {
    return (
      <Dropdown
        felt={felt}
        disabled={disabled}
        verdi={verdi}
        onChange={onChange}
        onFocus={onFocus}
        feil={feil}
        valider={valider}
        obligatorisk={obligatorisk}
        valideringsstate={valideringsstate}
        feltReferanse={feltReferanse}
      />
    );
  }
  if (feltUtils.feltErRadio(felt)) {
    return (
      <Radiogruppe
        feil={feil}
        valideringsstate={valideringsstate}
        valider={valider}
        felt={felt}
        valgliste={valgliste}
        verdi={verdi}
        onChange={onChange}
        onFocus={onFocus}
        obligatorisk={obligatorisk}
        disabled={disabled}
      />
    );
  }
  return null;
};
