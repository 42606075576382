import { useEffect, useRef } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import get from 'lodash/get';

import ErrorMessage from '@skatteetaten/frontend-components/ErrorMessage';
import style from './KortIDialog.module.scss';
import {
  language,
  visningslogikk,
} from '@skatteetaten/visningsdefinisjoner-og-tekster';
import { values } from '../../../utils/object';
import { FeltgruppeIDialog } from './utils/FeltgruppeIDialog';
import { FeltIDialog } from './utils/FeltIDialog';
import { RepeterendefeltIDialog } from './utils/RepeterendefeltIDialog';
import { DynamiskGruppeIDialog } from './DynamiskGruppeIDialog';
import { lagDatasti } from '../../../utils/visningsdataUtil';
import {
  DYNAMISK_GRUPPE,
  FELT,
  FELT_MED_EGENSKAPER,
  FELTGRUPPE,
  FELTSEPARATOR,
  FLER_FELT_VISNING,
  medForekomst,
  REPETERENDE_FELTGRUPPE,
} from '../../../utils/visningsDataDefinisjonUtil';
import { DialogKnapperad } from './utils/DialogKnapperad';
import { DialogKnapp } from './utils/DialogKnapp';
import { DialogAvbryt } from './utils/DialogAvbryt';
import { DialogOk } from './utils/DialogOk';
import { Feltseparator, skalViseFeltseparator } from '../Feltseparator';
import { FlerFeltVisning } from '../FlerFeltVisning';
import { FeltMedEgenskaperIDialog } from './utils/FeltMedEgenskaperIDialog';
import { useDispatcher } from '../../../utils/reduxHooks';
import { slettEgenskap } from './KortOpplysninger/kortOpplysninger.actions';
import { FeltIDialogWrapper } from './utils/FeltIDialogWrapper';
import {
  getDataIDialog,
  getDialogErITabellredigering,
  getDialogFeltSomErEndret,
  getDialogTemaId,
  getFeilIDialog,
  getKortdefinisjonIDialog,
  getNyBehandlingsart,
  getSistOkKlikketTimestamp,
  getSynligeEgenskaperIDialog,
  getTypeIDialog,
  getVisningsdata,
} from '../../../reducers';
import { overskrivFeilIDialog } from '../../../reducers/feil.action';
import { okKlikket } from '../../../actions/dialog';
import { LeggTilKortopplysninger } from './LeggTilKortopplysninger';
import { useLagreDialog, useSlettKortViaDialog } from './dialogHooks';
import { useBetingelseHook } from '../../../hooks/useBetingelseHook';
import { scrollTilFoersteFeil } from '../../../utils/scrollUtils';
import { useInntektsaar } from '../../../hooks/useInntektsaar';
import { useValideringsstate } from '../../../hooks/useValideringsstate';
import { lagFeilmeldinger } from '../../../utils/feltUtils';
import { useAnalytics } from '../../../analytics/analytics.hooks';
import { useKildeSkattPaaLoenn } from '../kildeskattePaaLoenn/useKildeskattPaaLoenn';

const { transformer, valider } = visningslogikk;

export const KortIDialog = ({ erIStegDialog }) => {
  const intl = useIntl();
  const { inntektsaar } = useInntektsaar();
  const valideringsstate = useValideringsstate();

  const kortdefinisjon = useSelector(getKortdefinisjonIDialog);
  const temaId = useSelector(getDialogTemaId);
  const feltSomErEndret = useSelector(getDialogFeltSomErEndret);
  const visningsdata = useSelector(getVisningsdata);
  const data = useSelector(getDataIDialog);
  const erITabellredigering = useSelector(getDialogErITabellredigering);
  const type = useSelector(getTypeIDialog);
  const sistOkKlikketTimestamp = useSelector(getSistOkKlikketTimestamp);
  const nyBehandlingsart = useSelector(getNyBehandlingsart);
  const synligeEgenskaper = useSelector(getSynligeEgenskaperIDialog);
  const feil = useSelector(getFeilIDialog);
  const feilForKort = get(feil, kortdefinisjon.id);
  const { beregnKplModus } = useKildeSkattPaaLoenn();

  const dialogDomElement = useRef(null);

  const okKlikket_ = useDispatcher(okKlikket);
  const _slettEgenskap = useDispatcher(slettEgenskap);
  const overskrivFeilIDialog_ = useDispatcher(overskrivFeilIDialog);

  const lagre = useLagreDialog();
  const slett = useSlettKortViaDialog();
  const { foelgerBetingelse, skalFeltSkjules, skalFeltDisables } =
    useBetingelseHook();
  const analytics = useAnalytics(temaId);

  useEffect(() => {
    if (!nyBehandlingsart && ['ny', 'endre'].includes(type)) {
      overskrivFeilIDialog_(validerDialog());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type]);

  useEffect(() => {
    dialogDomElement.current && scrollTilFoersteFeil(dialogDomElement.current);
  }, [sistOkKlikketTimestamp]);

  const validerDialog = () =>
    valider.feilmeldingerForKortType(
      kortdefinisjon,
      data,
      valideringsstate,
      data,
      visningsdata,
    );

  const kanLukkeDialog = !erITabellredigering;
  const dataSti = medForekomst(lagDatasti(kortdefinisjon.referanse), data.id);

  const readOnly =
    kortdefinisjon.laastISmeGittBetingelse &&
    foelgerBetingelse(data, kortdefinisjon.laastISmeGittBetingelse);

  const { header, subHeader, barnliste } = kortdefinisjon;

  const submitHandler = (event) => {
    event.preventDefault();
    // eslint-disable-next-line no-shadow
    const feilmeldinger = validerDialog();
    if (
      values(feilmeldinger).some(
        (feilmeldingliste) => feilmeldingliste.length > 0,
      )
    ) {
      okKlikket_();
      overskrivFeilIDialog_(feilmeldinger);
    } else {
      lagre();

      Object.values(feltSomErEndret).forEach((endretFelt) =>
        analytics.endringAvFeltIDialog(endretFelt.feltId, endretFelt.nyVerdi),
      );
      analytics.leggTilFullfoert();
    }
  };

  const slettHandler = () => {
    slett();
    analytics.sletteKortKlikk(kortdefinisjon.id);
  };

  const kortTittel =
    header?.visIDialog &&
    transformer.transformerHeader(header, data, kortdefinisjon, { intl });

  const kortUndertittel = beregnKplModus ? (
    <p>
      {intl.formatMessage({ id: 'ledetekst.kildeskattPaaLoenn.loennsfelt' })}
    </p>
  ) : (
    subHeader?.visIDialog &&
    transformer.transformerSubHeader(subHeader, data, kortdefinisjon, { intl })
  );

  const erSynlig = (felt) => {
    // eslint-disable-next-line no-shadow
    const { erEgenskapPaaObjekt } = felt;
    if (!erEgenskapPaaObjekt) {
      return true;
    }

    return Object.keys(synligeEgenskaper || {}).includes(felt.typeEgenskap);
  };

  const erKortFraBeregning = kortdefinisjon.kortFraBeregning;
  const erEgenfastsatt = data.meta === 'endretEgenfastsetting';
  const erNyttKort = type === 'ny';

  const kanIkkeSlettesGitt = () => {
    if (kortdefinisjon.kanIkkeSlettesGitt === undefined) {
      return false;
    }
    return foelgerBetingelse(data, kortdefinisjon.kanIkkeSlettesGitt);
  };

  const kortKanSlettes =
    !erNyttKort &&
    !erKortFraBeregning &&
    !beregnKplModus &&
    (erEgenfastsatt ||
      (inntektsaar > 2018 &&
        !kortdefinisjon.kanIkkeSlettes &&
        !kanIkkeSlettesGitt()));

  return (
    <form onSubmit={submitHandler}>
      <div ref={dialogDomElement}>
        {kortTittel && <h5>{kortTittel}</h5>}
        {!!kortUndertittel && kortUndertittel}

        <div>
          {kortdefinisjon &&
            barnliste
              .filter((barn) => !barn.skjulIDialog)
              .filter((barn) => !skalFeltSkjules(barn, data))
              .filter(erSynlig)
              .map((barn) => {
                const disabled = skalFeltDisables(barn, data);
                switch (barn.type) {
                  case FELT:
                    const kanSlettes =
                      barn.erEgenskapPaaObjekt &&
                      !(readOnly || erITabellredigering);
                    return (
                      <FeltIDialogWrapper
                        key={barn.id}
                        onSlett={kanSlettes && (() => _slettEgenskap(barn))}
                        felt={barn}
                        readOnly={readOnly}
                      >
                        <FeltIDialog
                          disabled={disabled}
                          readOnly={readOnly || erITabellredigering}
                          felt={barn}
                          feltReferanse={barn.referanse}
                          feltData={get(data, barn.referanse)}
                          kortdefinisjon={kortdefinisjon}
                          meta={data.meta}
                          forekomstId={data.id}
                        />
                      </FeltIDialogWrapper>
                    );
                  case REPETERENDE_FELTGRUPPE:
                    return (
                      <RepeterendefeltIDialog
                        felt={barn}
                        data={data}
                        kortdefinisjon={kortdefinisjon}
                        key={barn.id}
                        readOnly={readOnly}
                        dataStiTilForelder={dataSti}
                      />
                    );
                  case FELTGRUPPE:
                    return (
                      <FeltgruppeIDialog
                        feltgruppe={barn}
                        data={data}
                        kortdefinisjon={kortdefinisjon}
                        temaId={temaId}
                        key={barn.id}
                        readOnly={readOnly || erITabellredigering}
                        dataSti={dataSti}
                      />
                    );
                  case DYNAMISK_GRUPPE:
                    return (
                      <DynamiskGruppeIDialog
                        key={barn.id}
                        data={data}
                        kortdefinisjon={kortdefinisjon}
                        gruppe={barn}
                        referanse={barn.referanse}
                        temaId={temaId}
                        readOnly={readOnly || erITabellredigering}
                      />
                    );
                  case FELT_MED_EGENSKAPER:
                    return (
                      <FeltMedEgenskaperIDialog
                        key={barn.id}
                        disabled={disabled}
                        data={data}
                        readOnly={readOnly}
                        kortdefinisjon={kortdefinisjon}
                        felt={barn}
                        temaId={temaId}
                        lesmodus={barn.modus === 'les'}
                        feltReferanse={barn.referanse}
                      />
                    );
                  case FELTSEPARATOR:
                    if (skalViseFeltseparator('kort', barn)) {
                      return <Feltseparator key={barn.id} felt={barn} />;
                    }
                    return null;
                  case FLER_FELT_VISNING:
                    return (
                      <FlerFeltVisning
                        key={barn.id}
                        feltdefinisjon={barn}
                        kortdefinisjon={kortdefinisjon}
                        forekomstData={data}
                      />
                    );
                  default:
                    return null;
                }
              })}
        </div>
      </div>

      {!beregnKplModus && (
        <LeggTilKortopplysninger
          kortdefinisjon={kortdefinisjon}
          forekomst={data}
          readOnly={readOnly}
          disabled={erITabellredigering}
        />
      )}

      {feilForKort?.length > 0 && (
        <ErrorMessage>
          {lagFeilmeldinger(
            feilForKort,
            { kortReferanse: kortdefinisjon.referanse },
            intl,
            null,
            kortdefinisjon.id,
          )}
        </ErrorMessage>
      )}

      {!erIStegDialog && (
        <DialogKnapperad>
          {!readOnly && (
            <span className={style.actionButtons}>
              <span>
                {kortKanSlettes && (
                  <DialogKnapp
                    tekst={intl.formatMessage({
                      id: language.slettKnappId(kortdefinisjon.id),
                      defaultMessage: intl.formatMessage({ id: 'knapp.fjern' }),
                    })}
                    aksjon={slettHandler}
                    disabled={!kanLukkeDialog}
                  />
                )}
              </span>
              <span>
                <DialogAvbryt aksjon={() => analytics.endringKansellert()} />
                <DialogOk
                  hoved
                  submit
                  aksjon={submitHandler}
                  disabled={!kanLukkeDialog}
                  lukkEtterAksjon={false}
                />
              </span>
            </span>
          )}
          {readOnly && <DialogOk hoved disabled={!kanLukkeDialog} />}
        </DialogKnapperad>
      )}
    </form>
  );
};
