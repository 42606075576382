import { useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import isEmpty from 'lodash/isEmpty';

import { language } from '@skatteetaten/visningsdefinisjoner-og-tekster';
import { useDispatcher } from '../../../utils/reduxHooks';
import ActionButton from '@skatteetaten/frontend-components/ActionButton';
import style from './KortIDialog.module.scss';
import { getDialog } from '../../../reducers';
import {
  byttDialog,
  DIALOGTYPE_LEGG_TIL_OPPLYSNINGER,
} from '../../../actions/dialog';
import FormattedHTMLMessage from '../FormattedHTMLMessage';
import {
  egenskaperSomErLagtTil,
  finnAlleEgenskaper,
  finnDynamiskeFelterSomIkkeErLagtTil,
} from './KortOpplysninger/tilleggsopplysningerUtil';

export const LeggTilKortopplysninger = ({
  kortdefinisjon,
  forekomst,
  readOnly,
  disabled = false,
}) => {
  const {
    data: dialogData,
    dialogtype,
    synligeEgenskaper = {},
    kortIKortDialogProps: { underforekomstDataSti = {} } = {},
  } = useSelector(getDialog);

  const visFlereKortOpplysningerDialog = useDispatcher(
    byttDialog,
    DIALOGTYPE_LEGG_TIL_OPPLYSNINGER,
    dialogData,
    dialogtype,
  );
  const intl = useIntl();
  const { id } = kortdefinisjon;

  const alleEgenskaper = finnAlleEgenskaper(kortdefinisjon, forekomst);
  const egenskaperSomKanLeggesTil = alleEgenskaper.filter(
    egenskaperSomErLagtTil(synligeEgenskaper),
  );
  const dynamiskeFelterSomIkkeErLagtTil = finnDynamiskeFelterSomIkkeErLagtTil(
    kortdefinisjon,
    dialogData,
    underforekomstDataSti,
  );

  const harTilleggsopplysninger =
    !isEmpty(egenskaperSomKanLeggesTil) ||
    !isEmpty(dynamiskeFelterSomIkkeErLagtTil);
  const hasMessage = (tekstId) => !!intl.messages[tekstId];

  return (
    !readOnly &&
    harTilleggsopplysninger && (
      <fieldset className={style.leggTilOpplysningerTekst}>
        {hasMessage(language.flereopplysningerId(id, 'header')) && (
          <FormattedMessage
            id={language.flereopplysningerId(id, 'header')}
            tagName="legend"
          />
        )}
        {hasMessage(language.flereopplysningerId(id, 'beskrivelse')) && (
          <FormattedHTMLMessage
            id={language.flereopplysningerId(id, 'beskrivelse')}
            tagName="div"
          />
        )}
        <ActionButton
          disabled={disabled}
          icon="AddOutline"
          onClick={visFlereKortOpplysningerDialog}
          ariaLabel={intl.formatMessage({
            id: 'knapp.leggTilFlereOpplysninger',
            defaultMessage: 'Legg til flere opplysninger',
          })}
        >
          {intl.formatMessage({
            id: 'knapp.leggTilFlereOpplysninger',
            defaultMessage: 'Legg til flere opplysninger',
          })}
        </ActionButton>
      </fieldset>
    )
  );
};
