import { useSelector } from 'react-redux';
import { getVisningsdataSkattemelding } from '../../../reducers';
import { useVdotContext } from '../VdotContext';
import { useInntektsaar } from '../../../hooks/useInntektsaar';
import { visningsdefinisjonKonstanter } from '@skatteetaten/visningsdefinisjoner-og-tekster';
import { getVilBeregneKildeskattPaaLoenn } from '../../../reducers/skatteplikt';

const useSkattmeldingKonstanter = () => {
  const { vdotAar } = useInntektsaar();
  return visningsdefinisjonKonstanter[vdotAar].skattemelding;
};

export const useKildeSkattPaaLoenn = () => {
  const beregnKplModus = useSelector(getVilBeregneKildeskattPaaLoenn);
  const { TEMA, KORTGRUPPER, KORT } = useSkattmeldingKonstanter();

  const erBeregnKplOgTemaSkalSkjules = (temaId: string): boolean =>
    beregnKplModus && temaId !== TEMA.ARBEID_TRYGD_PENSJON_ID;

  const erBeregnKplOgKortgruppeSkalSkjules = (kortgruppeId: string): boolean =>
    beregnKplModus &&
    kortgruppeId !== KORTGRUPPER.LOENN_OG_TILSVARENDE_YTELSER_ID;

  const erBeregnKplOgKortSkalSkjules = (kortReferanse: string): boolean =>
    beregnKplModus && kortReferanse !== KORT.ARBEIDSGIVER.REF;

  return {
    beregnKplModus,
    erBeregnKplOgTemaSkalSkjules,
    erBeregnKplOgKortgruppeSkalSkjules,
    erBeregnKplOgKortSkalSkjules,
  };
};

export const useBeregnKildeskattPaaLoenn = () => {
  const visningsdata = useSelector(getVisningsdataSkattemelding);
  const { inntektsaar } = useInntektsaar();
  const { kodelister } = useVdotContext();

  const { KORT, DYNAMISK_GRUPPE } = useSkattmeldingKonstanter();

  const trekkprosentKildeskattPaaLoenn = Number(
    kodelister[`${inntektsaar}_satserIFormuesOgInntektsskatt`]
      .trekkprosentKildeskattPaaLoenn.satsverdi,
  );

  // Vi vet det bare finnes 1 kort med 1 lønnsfelt
  const loenn =
    visningsdata[KORT.ARBEIDSGIVER.REF][0][
      DYNAMISK_GRUPPE.YTELSER_FRA_ARBEIDSGIVER.REF
    ][0];

  return {
    beregnetSkattKpl:
      (loenn.beloep.beloepIValuta * trekkprosentKildeskattPaaLoenn) / 100,
    kplTrekkprosent: trekkprosentKildeskattPaaLoenn,
  };
};
