import React, { useContext, useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { visningslogikk } from '@skatteetaten/visningsdefinisjoner-og-tekster';

import style from './Dropdown.module.scss';
import calloutStyle from './tema/KortgruppeHeader.module.scss';
import ErrorMessage from '@skatteetaten/frontend-components/ErrorMessage';
import ComboBox from '@skatteetaten/frontend-components/ComboBox';
import idgenerator from '../../utils/idgenerator';
import {
  getForekomstForDialog,
  getKodelisteConfig,
  getLocale,
} from '../../reducers';
import {
  lagFeilmeldinger,
  lagHjelpetekst,
  lagLabel,
  mapOpsjoner,
} from '../../utils/feltUtils';
import { SmeDialogContext } from './dialog/smeDialog/SmeDialog';
import { useAnalytics } from '../../analytics/analytics.hooks';

const { felt: feltUtils } = visningslogikk;

export const Dropdown = React.memo(
  ({
    valider,
    onChange,
    felt,
    verdi,
    feil,
    obligatorisk,
    uid,
    valideringsstate,
    onFocus,
    disabled,
    feltReferanse,
  }) => {
    const intl = useIntl();
    const locale = useSelector(getLocale);
    const dialogData = useSelector(getForekomstForDialog);
    const kodelisteConfig = useSelector(getKodelisteConfig);
    const { setPauseFocustrap } = useContext(SmeDialogContext);
    const analytics = useAnalytics();

    const harFeil = !isEmpty(feil);
    const feltId = `combobox-${feltReferanse || ''}-${felt.id}`;

    useEffect(() => {
      if (!disabled && typeof valider === 'function') {
        valider(verdi);
      }
    }, [valider, verdi, disabled]);

    const gyldigeOpsjoner = useMemo(
      () => feltUtils.gyldigeOpsjonerForFelt(kodelisteConfig, felt, dialogData),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [dialogData],
    );

    const handleOnClick = () => {
      setPauseFocustrap(true);
      analytics.dialogFeltKlikk(feltId);
    };

    const handleOnChange = useMemo(
      () =>
        (event, { value }) => {
          if (typeof valider === 'function') {
            valider(value);
          }
          if (typeof onChange === 'function') {
            onChange(value, event);
          }
          setPauseFocustrap(false);
        },
      [valider, onChange, setPauseFocustrap],
    );

    const beregnetUid = uid || idgenerator(felt.id);

    const feltDefMedGyldigeOpsjoner = {
      ...felt,
      opsjon: {
        ...felt.opsjon,
        verdier: gyldigeOpsjoner,
      },
    };

    const valgliste = mapOpsjoner(
      feltDefMedGyldigeOpsjoner,
      verdi,
      obligatorisk,
      intl,
      locale,
    );

    // fluent har en bug med at den ikke legger på korrekte klasser på combobox sin errorMessage
    // hvis innholdet i errormessage er en node, og ikke ren tekst. Send inn en node uansett for konsistens,
    // og tving korrekt stil på feilmelding
    const errorMessage = lagFeilmeldinger(
      feil,
      valideringsstate,
      intl,
      felt.id,
    );
    const errorMessageElement = errorMessage && <span>{errorMessage}</span>;

    return (
      <div className="comboboxHjelpetekstHackFremTilFREM301ErLevert">
        <ComboBox
          id={feltId}
          className={style.dropdown}
          disabled={disabled}
          selectedKey={verdi}
          onChange={handleOnChange}
          onClick={handleOnClick}
          onFocus={onFocus}
          options={valgliste}
          help={lagHjelpetekst(intl, felt)}
          label={lagLabel(intl, felt, obligatorisk)}
          allowFreeform={false}
          useComboBoxAsMenuWidth
          data-type={harFeil ? 'harFeil' : ''}
          onCalloutToggle={analytics.haandterHjelpetekstToggle(feltId)}
          labelWithCalloutProps={{
            customClassNames: { callout: `${calloutStyle.calloutStyle}` },
          }}
        />
        {!!errorMessageElement && (
          <div id={`${beregnetUid}_feilmelding`}>
            <ErrorMessage>{errorMessageElement}</ErrorMessage>
          </div>
        )}
      </div>
    );
  },
);
