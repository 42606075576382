import { cloneDeep, get, isEmpty, omit, set } from 'lodash';
import fp from 'lodash/fp';
import { visningslogikk } from '@skatteetaten/visningsdefinisjoner-og-tekster';
import { referanseForFelt } from './visningsdefinisjonUtil';
import {
  DYNAMISK_GRUPPE,
  erBeloepsfelt,
  FELT,
  FELT_MED_EGENSKAPER,
  FELTGRUPPE,
  fjernBeloepFraReferanse,
  REPETERENDE_FELTGRUPPE,
} from './visningsDataDefinisjonUtil';
import { harVerdi } from './visningsdataUtil';

const { felt: feltUtils } = visningslogikk;
const { feltSkalDeaktiveres, feltSkalSkjules, feltSkalSlettes } = feltUtils;

// const harDeaktivergitt = (felt) => felt?.deaktiverGitt;
// const medReferanse = (referanse) => (felt) => felt?.referanse === referanse;

const skalBeholdeVerdi = (def) => {
  const betingelse = Object.keys(def).find((k) =>
    ['skjulGitt', 'deaktiverGitt'].includes(k),
  );
  const { beholdVerdi = false } = def[betingelse];
  return beholdVerdi;
};

export const erDeaktivertEllerSkjult = (felt, data, visningsdata, kontroller) =>
  !!(
    feltSkalDeaktiveres(felt, data, visningsdata, kontroller) ||
    feltSkalSkjules(felt, data, visningsdata, kontroller)
  );

const belopsfeltHarVerdi = (definisjon, data) =>
  get(data, referanseForFelt(definisjon), '') !== '';

const slettVerdiHvisInaktiv = (
  felt,
  data,
  referanseData = {},
  skalReturnereSlettedeFelter = false,
  visningsdata,
) => {
  const skalSlettes = feltSkalSlettes(
    felt,
    {
      ...data,
      ...referanseData,
    },
    visningsdata,
  );

  if (skalSlettes) {
    const bleSlettet = data.bleSlettet || [];
    bleSlettet.push({
      feltId: felt.id,
      forekomstId: data.id,
      internEiendomsidentifikator: data.internEiendomsidentifikator,
    });

    if (skalReturnereSlettedeFelter) {
      return {
        ...fp.omit(fjernBeloepFraReferanse(felt.referanse), data),
        bleSlettet,
      };
    } else {
      return fp.omit(fjernBeloepFraReferanse(felt.referanse), data);
    }
  }

  return data;
};

const toemInaktiveFeltgruppeverdier = (
  feltgruppe,
  data,
  referanseData = {},
  skalReturnereSlettedeFelter = false,
  visningsdata = {},
) => {
  const skalSlettes = erDeaktivertEllerSkjult(
    feltgruppe,
    {
      ...data,
      ...referanseData,
    },
    visningsdata,
  );
  if (skalSlettes) {
    feltgruppe.barnliste.forEach((barn) => {
      data = omit(data, barn.referanse);
    });
  } else {
    data = toemInaktiveFeltverdier(
      feltgruppe,
      data,
      undefined,
      skalReturnereSlettedeFelter,
      visningsdata,
    );
  }

  return data;
};

const finnBehandlingsartDefinisjon = (definisjon, forekomst) =>
  definisjon.barnliste.find(
    (barn) => barn.datatype === forekomst.behandlingsart,
  );

/**
 * Rekursiv metode for å tømme deaktiverte- og skjulte feltverdier
 * @param definisjon Visningsdefinisjon, eller en del av den
 * @param data Forekomst eller subforekomst
 * @param referanseData Referanse som forekomsten i tillegg skal sjekkes mot. Subforekomst har behov for å sjekke mot forekomst,
 * og forekomst kan også ha behov for å sjekke mot subforekomst
 * @param skalReturnereSlettedeFelter - Boolean, Sier om metoden skal samle felter som blir tømt.
 * @param visningsdata Visningsdata for hele skattemeldingen
 * @returns {data}  - Hvis flagget skalReturnereSlettedeFelter er true returneres objektet bleSlettet sammen med {data}
 */
export const toemInaktiveFeltverdier = (
  definisjon = {},
  data = {},
  referanseData = {},
  skalReturnereSlettedeFelter = false,
  visningsdata,
) => {
  const { barnliste = [] } = definisjon;
  let klonetData = cloneDeep(data);

  // eslint-disable-next-line sonarjs/cognitive-complexity
  barnliste.forEach((barn) => {
    let feltData;
    switch (barn.type) {
      case FELT_MED_EGENSKAPER: {
        if (!belopsfeltHarVerdi(barn, klonetData)) {
          klonetData = omit(klonetData, barn.referanse);
        } else {
          const medEndringForFelt = slettVerdiHvisInaktiv(
            barn,
            klonetData,
            referanseData,
            skalReturnereSlettedeFelter,
            visningsdata,
          );
          const egenskaper = get(medEndringForFelt, barn.referanse);
          const medEndringForSubforekomster = toemInaktiveFeltverdier(
            barn,
            egenskaper,
            undefined,
            skalReturnereSlettedeFelter,
            visningsdata,
          );
          if (isEmpty(medEndringForSubforekomster)) {
            klonetData = omit(klonetData, barn.referanse);
          } else {
            set(klonetData, barn.referanse, medEndringForSubforekomster);
          }
        }
        break;
      }

      case REPETERENDE_FELTGRUPPE: {
        if (
          // ref. SH-7429, pensjonstabellen skal ikke fjernes fra modellen ved skjuling
          !erDeaktivertEllerSkjult(barn, klonetData, visningsdata) ||
          !skalBeholdeVerdi(barn)
        ) {
          feltData = get(klonetData, barn.referanse, [])
            .map((subforekomst) =>
              toemInaktiveFeltverdier(
                barn,
                subforekomst,
                klonetData,
                skalReturnereSlettedeFelter,
                visningsdata,
              ),
            )
            .filter((subforekomst) => !isEmpty(subforekomst))
            .filter(
              (subforekomst) =>
                !erDeaktivertEllerSkjult(
                  barn,
                  {
                    ...subforekomst,
                    ...klonetData,
                  },
                  visningsdata,
                ),
            );
          if (feltData.length) {
            set(klonetData, barn.referanse, feltData);
          } else {
            klonetData = omit(klonetData, barn.referanse);
          }
        }
        break;
      }

      case DYNAMISK_GRUPPE: {
        feltData = get(klonetData, barn.referanse, [])
          .filter((subforekomst) => {
            // eslint-disable-next-line no-shadow
            const definisjon = finnBehandlingsartDefinisjon(barn, subforekomst);
            return (
              definisjon &&
              !erDeaktivertEllerSkjult(
                definisjon,
                {
                  ...subforekomst,
                  ...klonetData,
                },
                visningsdata,
              )
            );
          })
          .map((subforekomst) => {
            // eslint-disable-next-line no-shadow
            const definisjon = finnBehandlingsartDefinisjon(barn, subforekomst);
            return (
              definisjon &&
              toemInaktiveFeltverdier(
                definisjon,
                subforekomst,
                klonetData,
                skalReturnereSlettedeFelter,
                visningsdata,
              )
            );
          });
        set(klonetData, barn.referanse, feltData);
        break;
      }

      case FELTGRUPPE:
        klonetData = toemInaktiveFeltgruppeverdier(
          barn,
          klonetData,
          referanseData,
          skalReturnereSlettedeFelter,
          visningsdata,
        );
        break;

      case FELT:
        const harEgenskaperMedVerdi = () =>
          Object.values(
            data[fjernBeloepFraReferanse(barn.referanse)] || {},
          ).some(harVerdi);

        if (
          erBeloepsfelt(barn) &&
          !belopsfeltHarVerdi(barn, klonetData) &&
          harEgenskaperMedVerdi()
        ) {
          // Vanlige beløpsfelt kan få satt egenskaper automatisk, f.eks. som del
          // beregning. Da må vi rydde bort objektet dersom verdi slettes.
          const referanseUtenBeloep = fjernBeloepFraReferanse(barn.referanse);
          klonetData = omit(klonetData, referanseUtenBeloep);
        } else {
          klonetData = slettVerdiHvisInaktiv(
            barn,
            klonetData,
            referanseData,
            skalReturnereSlettedeFelter,
            visningsdata,
          );
        }
        break;

      default:
        break;
    }
  });

  return klonetData;
};
