import { MutableRefObject, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';

import { Tabs } from '@skatteetaten/frontend-components/Tabs';
import { TabItem } from '@skatteetaten/frontend-components/Tabs/TabItem';
import LabelWithCallout from '@skatteetaten/frontend-components/LabelWithCallout';
import { Panel } from '@skatteetaten/ds-content';
import { ArrowDownSVGpath, CalculatorSVGpath } from '@skatteetaten/ds-icons';
import { Icon } from '../components/DS/Icon';
import { Button } from '../components/DS/Button';

import style from './Forside.module.scss';
import kortgruppeStyle from '../components/tema/KortgruppeHeader.module.scss';
import { FormattedHTMLMessageWithFallbackToUgly } from '../components/FormattedHTMLMessage';
import { Skattemeldingside } from '../components/SkattemeldingSide';
import { useDispatcher } from '../../utils/reduxHooks';
import {
  gaaTilEktefelleSide,
  gaaTilSkattemeldingSide,
} from '../../actions/navigasjon';
import { EKTEFELLESIDE, SKATTEMELDINGSIDE } from '../../reducers/navigasjon';
import { getGjeldendeSide } from '../../reducers';
import { getErSivilstandGift } from '../../reducers/skatteplikt';
import { useBeregnetSkattData } from '../../hooks/useAktivSkvis';
import { scrollToPosition } from '../../utils/scroll';
import { getPos } from '../../utils/scrollUtils';
import { useInntektsaar } from '../../hooks/useInntektsaar';
import { useAnalytics } from '../../analytics/analytics.hooks';
import { useKildeSkattPaaLoenn } from '../components/kildeskattePaaLoenn/useKildeskattPaaLoenn';
import { formaterTall } from '../components/util/FormatertTall';

export const Forside = () => {
  const intl = useIntl();
  const analytics = useAnalytics();

  const skatteberegningRef = useRef(null);
  const [isSkatteberegningOpen, setIsSkatteberegningOpen] = useState(false);

  const gjeldendeSide = useSelector(getGjeldendeSide);
  const erGift = useSelector(getErSivilstandGift);
  const { beregnKplModus } = useKildeSkattPaaLoenn();
  const dispatchTilSkattemelding = useDispatcher(gaaTilSkattemeldingSide);
  const dispatchTilEktefelle = useDispatcher(gaaTilEktefelleSide);

  const toggleIsSkatteberegningOpen = () =>
    setIsSkatteberegningOpen(!isSkatteberegningOpen);

  const gaaTil = (side: string) => {
    if (side === EKTEFELLESIDE && side !== gjeldendeSide) {
      dispatchTilEktefelle();
      analytics.knappKlikk(
        'Gå til skattemelding for ektefelle',
        'Skattemelding',
      );
    } else if (side === SKATTEMELDINGSIDE && side !== gjeldendeSide) {
      dispatchTilSkattemelding();
      analytics.knappKlikk(
        'Gå til skattemeldingsiden',
        'Skattemelding ektefelle',
      );
    }
  };

  return erGift && !beregnKplModus ? (
    <>
      <InfoboksBeregnSkattenDin />
      <EstimertSkattTopp
        skatteberegningRef={skatteberegningRef}
        setIsSkatteberegningOpen={setIsSkatteberegningOpen}
      />
      <Tabs
        onLinkClick={(item) => {
          gaaTil(item!.props!.itemKey!);
        }}
      >
        <TabItem
          headerText={intl.formatMessage({
            id: 'ledetekst.navigering.tilSkattemelding',
          })}
          itemKey={SKATTEMELDINGSIDE}
        >
          <Skattemeldingside
            skatteberegningRef={skatteberegningRef}
            isSkatteberegningOpen={isSkatteberegningOpen}
            toggleIsSkatteberegningOpen={toggleIsSkatteberegningOpen}
          />
        </TabItem>
        <TabItem
          headerText={intl.formatMessage({
            id: 'ledetekst.navigering.tilSkattemeldingEktefelle',
          })}
          itemKey={EKTEFELLESIDE}
        >
          <Skattemeldingside
            skatteberegningRef={skatteberegningRef}
            isSkatteberegningOpen={isSkatteberegningOpen}
            toggleIsSkatteberegningOpen={toggleIsSkatteberegningOpen}
          />
        </TabItem>
      </Tabs>
    </>
  ) : (
    <>
      {!beregnKplModus && (
        <>
          <InfoboksBeregnSkattenDin />
          <EstimertSkattTopp
            skatteberegningRef={skatteberegningRef}
            setIsSkatteberegningOpen={setIsSkatteberegningOpen}
          />
        </>
      )}
      <Skattemeldingside
        skatteberegningRef={skatteberegningRef}
        isSkatteberegningOpen={isSkatteberegningOpen}
        toggleIsSkatteberegningOpen={toggleIsSkatteberegningOpen}
      />
    </>
  );
};

const InfoboksBeregnSkattenDin = () => {
  const intl = useIntl();

  return (
    <Panel
      title={intl.formatMessage({ id: 'header.infoboks.beregnSkattenDin' })}
      color={'denim'}
      variant={'filled'}
      renderIcon={() => (
        <div className={style.beregnSkattenDinIkon}>
          <Icon svgPath={CalculatorSVGpath} size={'extraLarge'} />
        </div>
      )}
      spacing={'m'}
      className={style.beregnSkattenDinInfoboks}
    >
      <FormattedHTMLMessageWithFallbackToUgly
        id={'ledetekst.infoboks.beregnSkattenDin.innhold'}
      />
    </Panel>
  );
};

const EstimertSkattTopp = ({
  skatteberegningRef,
  setIsSkatteberegningOpen,
}: {
  skatteberegningRef: MutableRefObject<HTMLDivElement | null>;
  setIsSkatteberegningOpen: (isOpen: boolean) => void;
}) => {
  const intl = useIntl();
  const { inntektsaar } = useInntektsaar();
  const beregnetSkattData = useBeregnetSkattData();
  const beregnetSkatt = beregnetSkattData?.beregnetSkatt | 0;

  const visDetaljer = () => {
    setIsSkatteberegningOpen(true);
    skatteberegningRef.current?.focus();
    scrollToPosition(getPos(skatteberegningRef.current));
  };

  return (
    <Panel color={'forest'} spacing="m" className={style.estimertSkattInfoboks}>
      <div className={style.paddingCorrection}>
        <span
          tabIndex={-1}
          role="heading"
          aria-level={3}
          className={style.helpIcon}
        >
          <LabelWithCallout
            label={intl.formatMessage(
              {
                id: 'header.infoboks.beregnetSkatt.topp',
              },
              {
                aar: inntektsaar,
              },
            )}
            help={intl.formatMessage({
              id: 'hjelpetekst.beregnetSkatt',
            })}
            customClassNames={{
              callout: kortgruppeStyle.calloutStyle,
              label: style.headerLabel,
            }}
          />
        </span>
        <div className={style.beloepRad}>
          <div className={style.beloepTekst}>
            {`${formaterTall(beregnetSkatt, intl)} kr`}
          </div>
          <div className={style.visDetaljerButton}>
            <Button variant={'secondary'} onClick={() => visDetaljer()}>
              <Icon svgPath={ArrowDownSVGpath} />
              {intl.formatMessage({ id: 'knapp.vis.detaljer' })}
            </Button>
          </div>
        </div>
      </div>
    </Panel>
  );
};
