import { useIntl } from 'react-intl';
import { isEmpty } from 'lodash';
import { visningslogikk } from '@skatteetaten/visningsdefinisjoner-og-tekster';

import RadioButtonGroup from '@skatteetaten/frontend-components/RadioButtonGroup';
import calloutStyle from '../tema/KortgruppeHeader.module.scss';
import {
  lagFeilmeldinger,
  lagHjelpetekst,
  lagLabel,
} from '../../../utils/feltUtils';
import { useAnalytics } from '../../../analytics/analytics.hooks';

const { transformer } = visningslogikk;

export const Radiogruppe = ({
  id,
  felt,
  feil,
  valideringsstate,
  valgliste,
  verdi,
  obligatorisk,
  onChange,
  visningsdata,
  inntektsaar,
  valider,
  disabled,
}) => {
  const intl = useIntl();
  const analytics = useAnalytics();

  const options = valgliste.map((valg) => ({
    key: valg,
    text: transformer.transformerFelt(felt, valg, {
      visningsdata,
      inntektsaar,
      erIDialog: true,
      intl,
    }),
    value: valg,
    name: felt.id,
    disabled: disabled,
  }));

  const handleOnChange = (event, { value }) => {
    if (typeof valider === 'function') {
      valider(value);
    }
    if (typeof onChange === 'function') {
      onChange(value, event);
    }
  };

  const handleFokus = () => analytics.dialogFeltKlikk(felt.id);

  return (
    <RadioButtonGroup
      id={id}
      options={options}
      defaultSelectedKey={verdi}
      onChange={handleOnChange}
      onFocus={handleFokus}
      label={lagLabel(intl, felt, obligatorisk)}
      errorMessage={lagFeilmeldinger(feil, valideringsstate, intl, felt.id)}
      data-type={isEmpty(feil) ? '' : 'harFeil'}
      help={lagHjelpetekst(intl, felt)}
      onCalloutToggle={analytics.haandterHjelpetekstToggle(felt.id)}
      labelWithCalloutProps={{
        customClassNames: { callout: `${calloutStyle.calloutStyle}` },
      }}
    />
  );
};
