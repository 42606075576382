import { useCallback, useEffect } from 'react';
import { useDispatch, useStore } from 'react-redux';

import { getSynligSeksjon } from '../reducers/navigasjon';
import { scrolletTilSeksjon } from '../actions/navigasjon';
import { circaHeaderHoeyde } from '../web/components/StickyHeader';

/**
 * Denne hooken sier ifra hvilken seksjon som er synlig, som igjen vises i StickyHeader. Brukes i tema(er) og oppsummering.
 */
export const useSynligSeksjon = (id, ref) => {
  const store = useStore();
  const dispatch = useDispatch();

  const scrollListener = useCallback(() => {
    const synligTema = getSynligSeksjon(store.getState());

    const rect = ref.current?.getBoundingClientRect();

    const erITema =
      rect && rect.top < circaHeaderHoeyde && rect.bottom > circaHeaderHoeyde;
    const nyttTema = erITema && id !== synligTema;

    if (nyttTema) {
      dispatch(scrolletTilSeksjon(id));
    }
  }, [ref, id, dispatch, store]);

  useEffect(() => {
    document.addEventListener('scroll', scrollListener);

    return () => document.removeEventListener('scroll', scrollListener);
  }, [scrollListener]);
};
