import {
  GAA_TIL_EKTEFELLESIDE,
  GAA_TIL_EKTEFELLESIDE_SUKSESS,
  GAA_TIL_SKATTEMELDING,
  GAA_TIL_SKATTEMELDING_SUKSESS,
  SCROLLET_TIL_SEKSJON,
} from '../actions/navigasjon';

export const SKATTEMELDINGSIDE = 'skattemelding';
export const EKTEFELLESIDE = 'ektefelleside';

export const getGjeldendeSide = (state) => state.gjeldendeSide;
export const getSynligSeksjon = (state) => state.navigasjon.synligSeksjon;
export const getSideNavigasjonPaagaar = (state) => state.sideNavigasjonPaagaar;

const defaultState = {
  gjeldendeSide: SKATTEMELDINGSIDE,
  synligSeksjon: null,
  sideNavigasjonPaagaar: false,
};

export default function navigasjonsReducer(state = defaultState, action = {}) {
  switch (action.type) {
    case GAA_TIL_SKATTEMELDING:
    case GAA_TIL_EKTEFELLESIDE:
      return {
        ...defaultState,
        gjeldendeSide: state.gjeldendeSide,
        sideNavigasjonPaagaar: true,
      };
    case GAA_TIL_SKATTEMELDING_SUKSESS:
      return {
        ...state,
        gjeldendeSide: SKATTEMELDINGSIDE,
      };
    case GAA_TIL_EKTEFELLESIDE_SUKSESS:
      return {
        ...state,
        gjeldendeSide: EKTEFELLESIDE,
      };
    case SCROLLET_TIL_SEKSJON:
      return {
        ...state,
        synligSeksjon: action.seksjonId,
      };

    default:
      return state;
  }
}
