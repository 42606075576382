import './styles.scss';
import { useMemo } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Provider } from 'react-redux';
import { useIntl } from 'react-intl';
import '@skatteetaten/ds-core-designtokens/index.css';

import storeWrapper from './../store';
import analytics from '../analytics/matomoCoreUtil';
import { webContextPath } from './constants';
import PageNotFound from './components/PageNotFound';
import { Forside } from './routes/Forside';
import { Layout } from './components/Layout';
import { Dialog } from './components/dialog/Dialog';
import { SmeDialogProvider } from './components/dialog/smeDialog/SmeDialog';
import { VdotContextProvider } from './components/VdotContext';
import { Skattepliktsopplysninger } from './routes/Skattepliktsopplysninger';
import { useInntektsaar } from '../hooks/useInntektsaar';
import { VelgKalkulator } from './routes/VelgKalkulator';
import { Spinner } from './components/Spinner';
import { SystemmeldingDialog } from './components/dialog/SystemmeldingDialog/SystemmeldingDialog';
import { useKildeSkattPaaLoenn } from './components/kildeskattePaaLoenn/useKildeskattPaaLoenn';
// import { FlereFanerDialog } from './FlereFanerDialog';

export const App = () => {
  return (
    <SmeDialogProvider>
      <Provider store={storeWrapper.store}>
        <VdotContextProvider>
          {/*<SiteLayout>*/}
          <Innhold />
          {/*</SiteLayout>*/}
          <Dialog />
          {/*<FlereFanerDialog />*/}
          <SystemmeldingDialog />
        </VdotContextProvider>
      </Provider>
    </SmeDialogProvider>
  );
};

const Innhold = () => {
  const intl = useIntl();
  const { inntektsaar } = useInntektsaar();
  const { beregnKplModus } = useKildeSkattPaaLoenn();
  useMemo(() => {
    analytics.createTracker(inntektsaar);
  }, [inntektsaar]);

  const tittelId = beregnKplModus ? 'header.tittel.kpl' : 'header.tittel';

  return (
    <BrowserRouter basename={webContextPath}>
      <Layout
        title={intl.formatMessage(
          {
            id: tittelId,
          },
          {
            aar: inntektsaar,
          },
        )}
      >
        <Spinner />
        <Routes>
          <Route path="/" element={<VelgKalkulator />} />
          <Route path="/velgKalkulator" element={<VelgKalkulator />} />
          <Route
            path="/skattekalkulator/skatteplikt"
            element={<Skattepliktsopplysninger />}
          />
          <Route path="/skattekalkulator/:inntektsaar" element={<Forside />} />
          <Route element={<PageNotFound />} />
        </Routes>
      </Layout>
    </BrowserRouter>
  );
};
